.contact_container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 60px;
}

.form_container {
    display: flex;
    justify-content: center;
}

.form_contact_info_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media only screen and (max-width: 899px) {

    .form_container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .form_contact_info_container {
        flex-direction: column;
        gap: 0px;
    }

}