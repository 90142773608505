.footer_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #03163f;
    margin-top: 30px;
    height: 4rem;
}

.footer_item {
    color: #f0f8ff;
    font-size: 1.2rem;
}

.footer_item a {
    color: #f0f8ff;
    text-decoration: none;
}

@media only screen and (max-width: 899px) {
    .footer_item {
        font-size: 0.9rem;
    }
}