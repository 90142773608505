.navbar {
    display: flex;
    align-items: center;
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #03163f;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.logo {
    justify-content: flex-start;
    padding-right: 5px;
    font-size: xx-large;
    font-weight: bold;
    color: #3e8bf8;
}

.cursor {
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.logo a {
    color: #3e8bf8;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.links {
    margin: 0 10rem 0 0 ;
    padding: 0;
    list-style-type: square;
    color: #f0f8ff;
    font-size: large;
    font-weight: bold;
}

.links li {
    display: inline;
    padding: 0 2rem 0 0;
}

.links a {
    color: #f0f8ff;
}

.links a:hover {
    text-decoration: none;
    color: rgb(207, 215, 222);
    transition: 250ms linear;
}

@media only screen and (min-width: 900px) {

}

@media only screen and (max-width: 899px) {
    .links {
        display: none;
    }

    .navbar {
        justify-content: center;
    }
}

