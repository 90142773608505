.audits_container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.cursor {
    animation: blinker 1s linear infinite;
    font-weight: normal;
    color: #3e8bf8
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.text {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 20px;
    font-size: 1.5em;
}

.text h1 {
    font-size: 3.5em;        
}

.text p {
    text-align: left;
}

.image_container {
    display: flex;
    margin: 20px;
}

.image {
    display: flex;
    height: 450px;
    border-radius: 25px;
}


@media only screen and (max-width: 899px) {
    .audits_container {
        flex-direction: column-reverse;
        align-items: center;
    }
    
    .image { 
        height: 300px;
    }

    .text h1 {
        font-size: 2rem;
    }

    .text p {
        font-size: 1rem;
    }

    .text {
        width: auto
    }
}