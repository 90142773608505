.container_about {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: #3e8bf8;
    margin-top: 30px;
}

.container_about h1 {
    font-size: 3em;
}

.card {
    display: flex;
    width: 200px;
    border: none;
}

.container_cards {
    width: 1200px;
    display: flex;
    justify-content: space-around;
}

.card_header {
    border: 1px solid;
    font-weight: bolder;
}

.card_body {
    color:rgb(110, 110, 110);
}

.title {
    display: flex;
    font-size: 3em;
}

.brand {
    color: #3e8bf8;
}

@media only screen and (max-width: 899px) {
    .container_cards {
        flex-direction: column;
        width: auto;
    }

    .title {
        font-size: 2em;
    }

    .card_header {
        font-size: 0.8em;
    }

    .card_body {
        font-size: 0.8em;
    }
}